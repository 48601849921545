<template>
  <v-list-item dense>
    <v-list-item-avatar tile>
      <planete-icon width="24" height="24">{{ category.icon }}</planete-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ notification.data.date }}
      </v-list-item-title>
      <v-list-item-subtitle v-for="horaire in notification.data.horaires.slice(0,3)" :key="horaire.fichier">
        {{ horaire.hour }} : {{ horaire.fichier }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="notification.data.horaires.length > 3">[...]</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "MatriculePaie",
  props: {
    notification: Object,
    category: Object
  }
}
</script>

<style scoped>

</style>